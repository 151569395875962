import React from "react";
import {
  Container,
  Section,
  Seo,
} from "@components";
import { PageLayout } from "../components/site/layout/page-layout";
import { HomeBanner } from "../components/site/home";
import { GoldStandardPanel } from "../components/site/gold-standard";
import { IntegrityPanel } from "../components/site/integrity-panel"
import { HowProtectsPanel } from "../components/site/how-panel"
import { MarketPanel } from "../components/site/markets"
import { ProtectsPanel } from "../components/site/protects-panel"

const HomePage = ({ location }) => {
  return (
    <>
      <PageLayout pathname={location.pathname}>
        <HomeBanner />

        <Section className="">
          <Container className={`py-[6rem] container-lg flex`}>
            <GoldStandardPanel />
          </Container>
        </Section>
        <Section className="bg-[#1b3d56] ">
          <Container className={`py-[6rem] container-lg`}>
              <ProtectsPanel />
            {/* <TestimonialSlider /> */}
          </Container>
        </Section>
        <Section className="">
          <Container className={`py-[6rem] container-lg`}>
            <IntegrityPanel />
          </Container>
        </Section>
        <Section className="bg-[#f0f0f0]">
          <Container className={`py-[6rem] container-lg`}>
            <HowProtectsPanel />
          </Container>
        </Section>
        <Section className="lg:block md:block hidden">
          <Container className={`py-[6rem] container-lg`}>
            <MarketPanel />
          </Container>
        </Section>
      </PageLayout>
    </>
  );
};

export default HomePage;
